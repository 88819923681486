// @ts-nocheck // this stops it from yelling about default values not being able to be null, even though it's fine
// this is because properties are yup.string().required().nullable(), so we want the initial/default value to be null
// but infering the type is demanding they are strings
import { PDWProduct } from './product.schema';
import { newLowerCallStrike } from '../pages/product-entry-and-edit/form-sections/ProductGrowthFormComponent';

export const initialPDWProductValue: PDWProduct = {
  createTimestamp: undefined,
  id: undefined,
  version: undefined,
  programs: undefined,
  previousStage: undefined,
  previousStatus: undefined,
  templateSelection: undefined,
  qcVerified: false,
  productGeneral: {
    structureNameExternal: undefined,
    structureShortNameExternal: undefined,
    structureLongNameExternal: undefined,
    structureNameInternal: undefined,
    productName: undefined,
    externalProductId: undefined,
    cusip: undefined,
    isin: undefined,
    requestId: undefined,
    issuer: undefined,
    tenorCurrent: undefined,
    tenorFinal: undefined,
    tenorLow: undefined,
    tenorHigh: undefined,
    tenorUnit: undefined,
    productNotionalFinal: undefined,
    productNotionalCurrent: undefined,
    productNotionalLow: undefined,
    productNotionalHigh: undefined,
    productNotionalCap: undefined,
    accountType: undefined,
    hedgeType: undefined,
    currency: undefined,
    productFaceValue: undefined,
    estimatedInitialValue: undefined,
    registrationType: undefined,
    tradeDate: undefined,
    strikeDate: undefined,
    issueDate: undefined,
    finalValuationDate: undefined,
    averagingInDates: [],
    averagingOutDates: [],
    aggregatingDates: [],
    maturityDate: undefined,
    settlementType: undefined,
    basketType: undefined,
    externalBasketName: undefined,
    lookbackInitialDate: undefined,
    reofferClientList: [],
    underlierList: [],
    productDeadlineTimestamp: undefined,
    productLaunchTimestamp: undefined,
    productNoteList: [],
    bidDeadlineTimestamp: undefined,
    fdicInsured: undefined,
    calculationAgent: undefined,
    stage: undefined,
    status: undefined,
    highWaterMarkDate: undefined,
    salesCreditCurrent: undefined,
    salesCreditFinal: undefined,
    salesCreditHigh: undefined,
    salesCreditLow: undefined,
    fairPriceFinal: undefined,
    lookbackInitialValuationType: undefined,
    lookbackFinalDate: undefined,
    lookbackFinalValuationType: undefined,
    averagingModified: undefined,
    averagingExclusion: undefined,
    supplementalPercentage: undefined,
    totalPnl: undefined,
    fundingSpread: undefined,
    oisDfPercentage: undefined,
    structureFee: undefined,
    riskFreeRate: undefined,
    swapMaturity: undefined,
    upfrontDeferred: undefined,
    indicativeDeadlineTimestamp: undefined,
    externalDocumentProvider: undefined,
    matchInformation: undefined,
    wrapperType: undefined,
    structureShortNameInternal: undefined,
    structureLongNameInternal: undefined,
    lumaProductIdentifier: undefined,
    assetClass: undefined,
    ancillaryFeatures: [],
    dayCountFraction: undefined,
    minimumTradingLot: undefined,
    longTermCapitalGain: undefined,
    returnType: undefined,
    originator: undefined,
    standardPrice: undefined,
    productRiskRanking: undefined,
    businessDayConvention: undefined,
    performanceStrike: undefined,
    basketReturnAdjustment: undefined,
    issuePriceCurrent: undefined,
    issuePriceFinal: undefined,
    issuePriceLow: undefined,
    issuePriceHigh: undefined,
    sspaStructureTypeName: undefined,
    sspaStructureTypeId: undefined,
    completionStatus: undefined,
    opsReviewStatus: undefined,
    secondaryMarketQuotingType: undefined,
    offeringType: undefined,
    productType: undefined,
    productCategory: undefined,
    dateOffset: undefined,
    fundservID: undefined,
    underlierRankWeightList: [],
  },
  productYield: {
    paymentType: undefined,
    paymentStepType: undefined,
    paymentEvaluationFrequencyCurrent: undefined,
    paymentEvaluationFrequencyFinal: undefined,
    paymentBarrierObservationFrequency: undefined,
    paymentDateList: undefined,
    paymentFrequency: undefined,
    paymentRatePerPeriodCurrent: undefined,
    paymentRatePerPeriodFinal: undefined,
    paymentRatePerPeriodLow: undefined,
    paymentRatePerPeriodHigh: undefined,
    paymentBarrierCurrent: undefined,
    paymentBarrierFinal: undefined,
    paymentBarrierLow: undefined,
    paymentBarrierHigh: undefined,
    paymentBarrierUnderlierList: undefined,
    paymentMemory: undefined,
    minPaymentRatePerAnnumCurrent: undefined,
    minPaymentRatePerAnnumFinal: undefined,
    minPaymentRatePerAnnumHigh: undefined,
    minPaymentRatePerAnnumLow: undefined,
    paymentRatePerAnnumCurrent: undefined,
    paymentRatePerAnnumFinal: undefined,
    paymentRatePerAnnumHigh: undefined,
    paymentRatePerAnnumLow: undefined,
    minPaymentRatePerPeriodCurrent: undefined,
    minPaymentRatePerPeriodFinal: undefined,
    minPaymentRatePerPeriodLow: undefined,
    minPaymentRatePerPeriodHigh: undefined,
    stockReturnCapCurrent: undefined,
    stockReturnCapFinal: undefined,
    stockReturnCapLow: undefined,
    stockReturnCapHigh: undefined,
    stockReturnCapUnderlierList: undefined,
    stockReturnFloorCurrent: undefined,
    stockReturnFloorFinal: undefined,
    stockReturnFloorLow: undefined,
    stockReturnFloorHigh: undefined,
    stockReturnFloorUnderlierList: undefined,
    variablePayInitialObservationDate: undefined,
    variablePayFinalObservationDate: undefined,
    minCouponPayPeriod: undefined,
    bifurcationOfInterest: undefined,
    interest: undefined,
    optionPremium: undefined,
    rateBuilderValuesCurrent: undefined,
    rateBuilderValuesFinal: undefined,
    rateBuilderValuesLow: undefined,
    rateBuilderValuesHigh: undefined,
    rateBuilderGrouping: undefined,
    rateBuilderReturns: undefined,
    leverageFactors: [],
    paymentTypeChangeDate: undefined,
    floatSpread: undefined,
    upperBarrierLevelCurrent: undefined,
    upperBarrierLevelFinal: undefined,
    upperBarrierLevelLow: undefined,
    upperBarrierLevelHigh: undefined,
    couponBarrierCrossingType: undefined,
    couponBasketType: undefined,
    equityPool: undefined,
    cashPoolKnockInLevel: undefined,
    cashPoolKnockInVolume: undefined,
    triggerParticipationRate: undefined,
    yieldBasketType: undefined,
    yieldCrossing: undefined,
  },
  productCall: {
    callType: undefined,
    callObservationDateList: undefined,
    callBarrierLevelCurrent: undefined,
    callBarrierLevelFinal: undefined,
    callBarrierLevelLow: undefined,
    callBarrierLevelHigh: undefined,
    callPremiumCurrent: undefined,
    callPremiumFinal: undefined,
    callPremiumLow: undefined,
    callPremiumHigh: undefined,
    callObservationFrequency: undefined,
    callBarrierLevelUnderlierList: undefined,
    numberNoCallPeriods: undefined,
    callPeriodObservationType: undefined,
    expectedMaturity: undefined,
    stepType: undefined,
    callBarrierStepIncrement: undefined,
    callPremiumStepIncrement: undefined,
    callBasketType: undefined,
    callCrossing: undefined,
    callMemory: undefined,
  },
  productProtection: {
    downsideType: undefined,
    putStrikeCurrent: undefined,
    putStrikeFinal: undefined,
    putStrikeLow: undefined,
    putStrikeHigh: undefined,
    putStrikeUnderlierList: undefined,
    principalBarrierLevelUnderlierList: undefined,
    principalBufferLevelUnderlierList: undefined,
    principalBarrierLevelCurrent: undefined,
    principalBarrierLevelFinal: undefined,
    principalBarrierLevelLow: undefined,
    principalBarrierLevelHigh: undefined,
    principalBufferLevelCurrent: undefined,
    principalBufferLevelFinal: undefined,
    principalBufferLevelLow: undefined,
    principalBufferLevelHigh: undefined,
    protectionLevel: undefined,
    putType: undefined,
    putObservationFrequency: undefined,
    putObservationDateList: undefined,
    putLeverageCurrent: undefined,
    putLeverageFinal: undefined,
    putLeverageLow: undefined,
    putLeverageHigh: undefined,
    capitalProtectionLevelCurrent: undefined,
    capitalProtectionLevelFinal: undefined,
    capitalProtectionLevelLow: undefined,
    capitalProtectionLevelHigh: undefined,
    downsideBasketType: undefined,
    KIbarrierCrossing: undefined,
    finalFixingCrossing: undefined,
    oneStarType: undefined,
    oneStarLevel: undefined, // question: is this stored as a decimal???
  },
  productGrowth: {
    digitalReturnCurrent: undefined,
    digitalReturnFinal: undefined,
    digitalReturnLow: undefined,
    digitalReturnHigh: undefined,
    upsideParticipationRateCurrent: undefined,
    upsideParticipationRateFinal: undefined,
    upsideParticipationRateLow: undefined,
    upsideParticipationRateHigh: undefined,
    upsideParticipationUnderlierList: undefined,
    lowerCallStrikeList: [newLowerCallStrike()],
    lowerCallStrikeUnderlierList: undefined,
    underlierReturnCapLevelCurrent: undefined,
    underlierReturnCapLevelFinal: undefined,
    underlierReturnCapLevelLow: undefined,
    underlierReturnCapLevelHigh: undefined,
    digitalReturnBarrierCurrent: undefined,
    digitalReturnBarrierFinal: undefined,
    digitalReturnBarrierLow: undefined,
    digitalReturnBarrierHigh: undefined,
    digitalReturnBarrierUnderlierList: undefined,
    digitalReturnBarrierObservationFrequency: undefined,
    digitalReturnBarrierObservationDateList: undefined,
    upsideAboveDigitalReturn: undefined,
    upsideParticipationAboveDigitalReturnCurrent: undefined,
    upsideParticipationAboveDigitalReturnFinal: undefined,
    upsideParticipationAboveDigitalReturnLow: undefined,
    upsideParticipationAboveDigitalReturnHigh: undefined,
    absoluteReturnBarrierLevelCurrent: undefined,
    absoluteReturnBarrierLevelFinal: undefined,
    absoluteReturnBarrierLevelLow: undefined,
    absoluteReturnBarrierLevelHigh: undefined,
    absoluteReturnBarrierUnderlierList: undefined,
    absoluteReturnParticipationRateCurrent: undefined,
    absoluteReturnParticipationRateFinal: undefined,
    absoluteReturnParticipationRateLow: undefined,
    absoluteReturnParticipationRateHigh: undefined,
    maximumReturnCurrent: undefined,
    maximumReturnFinal: undefined,
    maximumReturnLow: undefined,
    maximumReturnHigh: undefined,
    minimumReturnCurrent: undefined,
    minimumReturnFinal: undefined,
    minimumReturnLow: undefined,
    minimumReturnHigh: undefined,
    growthType: undefined,
    bearish: false, // question: false by default??
    lowerPutStrike: undefined,
    knockOutBarrierCurrent: undefined,
    knockOutBarrierFinal: undefined,
    knockOutBarrierLow: undefined,
    knockOutBarrierHigh: undefined,
    knockOutRebateCurrent: undefined,
    knockOutRebateFinal: undefined,
    knockOutRebateLow: undefined,
    knockOutRebateHigh: undefined,
    upsideBasketType: undefined,
  },
  productCln: {
    debtIsinList: [],
    debtWeightList: [],
    cdsName: undefined,
    cdsTenor: undefined,
    interestMultiplierPerYear: undefined,
    creditEvent: undefined,
    floatRateFixingOffsetDays: undefined,
    floatRateFixingTime: undefined,
    floatRateFixingSchedule: [],
    floatInstrument: undefined,
    fixedCouponMultiplier: undefined,
    rateCap: [],
    rateMin: [],
    rateConditionalCoupon: [],
    rateBarrierObservationFrequency: undefined,
    rateBarrierLevel: [],
    ratePaymentType: undefined,
  },
  productCms: {
    longTermRateUnderlierSymbol: undefined,
    longTermRateUnderlierSource: undefined,
    shortTermRateUnderlierSymbol: undefined,
    shortTermRateUnderlierSource: undefined,
  },
  clientSpecific: {
    clientProductId: undefined,
    splitIds: undefined,
    offeringScope: undefined,
    productCode: undefined,
    offshoreEligibility: undefined,
    locked: false, // question: false by default?
    prosRequestId: undefined,
    offeringNotification: undefined,
    proceedToOfferingStatus: undefined,
    broadOffering: undefined,
    dealLead: undefined,
    pvFundingLessFeesPercentage: undefined,
    productSymbol: undefined,
    legalTeam: undefined,
    legalPrintEstimate: undefined,
    issuerCreditCode: undefined,
    securityId: undefined,
    dealBackup: undefined,
    ramCode: undefined,
    trader: undefined,
    breakpointShares: undefined,
    numBreakpointInvestors: undefined,
    coreApproval: undefined,
    internalTradingDesk: undefined,
    firstPaymentDate: undefined,
    interestPeriod: undefined,
  },
  productRegulatory: {
    countryDistribution: undefined,
    listing: undefined,
    supportingDocumentation: undefined,
    targetMarket: undefined,
  },
  quotes: undefined,
  productId: undefined,
  productScenarios: undefined,
  accumulatorDecumulator: undefined,
  updateTimestamp: undefined,
  createdByUserId: undefined,
  updatedByUserId: undefined,
  opsReviewTimestamp: undefined,
  opsReviewUserId: undefined,
  revision: undefined,
  productDataSource: undefined,
};
