export enum PDWFormSectionIds {
  coreDetails = 'coreDetails',
  underliers = 'underliers',
  productDates = 'productDates',
  calendarDates = 'calendarDates',
  callDetails = 'callDetails',
  couponDetails = 'couponDetails',
  productGrowth = 'productGrowth',
  protectionDetails = 'protectionDetails',
  productPreferences = 'productPreferences',
  feeDetails = 'feeDetails',
  cLNDetails = 'cLNDetails',
  cMSDetails = 'cMSDetails',
  equityBuilders = 'equityBuilders',
  nonCoreDetails = 'nonCoreDetails',
  ancillaryFeatures = 'ancillaryFeatures',
  clientSpecifies = 'clientSpecifies',
}

export interface FormSectionProperties {
  expanded: boolean;
  label: string;
  formFields?: string[];
  component: any;
  id: string;
}
