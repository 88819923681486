import { TemplateOptions } from '../../../../product-schema/pdw-select-options';
import { PDWFormSectionIds } from '../../form-section.types';

export const DefaultSectionOrder = [
  PDWFormSectionIds.coreDetails,
  PDWFormSectionIds.underliers,
  PDWFormSectionIds.productDates,
  PDWFormSectionIds.calendarDates,
  PDWFormSectionIds.callDetails,
  PDWFormSectionIds.couponDetails,
  PDWFormSectionIds.productGrowth,
  PDWFormSectionIds.protectionDetails,
  PDWFormSectionIds.productPreferences,
  PDWFormSectionIds.feeDetails,
  PDWFormSectionIds.cLNDetails,
  PDWFormSectionIds.cMSDetails,
  PDWFormSectionIds.equityBuilders,
  PDWFormSectionIds.nonCoreDetails,
  PDWFormSectionIds.ancillaryFeatures,
  PDWFormSectionIds.clientSpecifies,
];
/*
This is a mapping for between the template section selected and the order in which the sections should be displayed
 */
export const templateSectionOrders = {
  [TemplateOptions.ALL_UP]: [
    PDWFormSectionIds.coreDetails,
    PDWFormSectionIds.underliers,
    PDWFormSectionIds.productDates,
    PDWFormSectionIds.calendarDates,
    PDWFormSectionIds.couponDetails,
    PDWFormSectionIds.protectionDetails,
    PDWFormSectionIds.callDetails,
    PDWFormSectionIds.productGrowth,
    PDWFormSectionIds.productPreferences,
    PDWFormSectionIds.feeDetails,
    PDWFormSectionIds.cLNDetails,
    PDWFormSectionIds.cMSDetails,
    PDWFormSectionIds.equityBuilders,
    PDWFormSectionIds.nonCoreDetails,
    PDWFormSectionIds.ancillaryFeatures,
    PDWFormSectionIds.clientSpecifies,
  ],
  [TemplateOptions.AUTO_CAP]: [
    PDWFormSectionIds.coreDetails,
    PDWFormSectionIds.underliers,
    PDWFormSectionIds.productDates,
    PDWFormSectionIds.calendarDates,
    PDWFormSectionIds.couponDetails,
    PDWFormSectionIds.protectionDetails,
    PDWFormSectionIds.callDetails,
    PDWFormSectionIds.productGrowth,
    PDWFormSectionIds.productPreferences,
    PDWFormSectionIds.feeDetails,
    PDWFormSectionIds.cLNDetails,
    PDWFormSectionIds.cMSDetails,
    PDWFormSectionIds.equityBuilders,
    PDWFormSectionIds.nonCoreDetails,
    PDWFormSectionIds.ancillaryFeatures,
    PDWFormSectionIds.clientSpecifies,
  ],
  [TemplateOptions.BEARISH]: [
    PDWFormSectionIds.coreDetails,
    PDWFormSectionIds.underliers,
    PDWFormSectionIds.productDates,
    PDWFormSectionIds.productGrowth,
    PDWFormSectionIds.protectionDetails,
    PDWFormSectionIds.calendarDates,
    PDWFormSectionIds.callDetails,
    PDWFormSectionIds.couponDetails,
    PDWFormSectionIds.productPreferences,
    PDWFormSectionIds.feeDetails,
    PDWFormSectionIds.cLNDetails,
    PDWFormSectionIds.cMSDetails,
    PDWFormSectionIds.equityBuilders,
    PDWFormSectionIds.nonCoreDetails,
    PDWFormSectionIds.ancillaryFeatures,
    PDWFormSectionIds.clientSpecifies,
  ],
  [TemplateOptions.CAPPED_DUAL_DIRECTIONAL]: [
    PDWFormSectionIds.coreDetails,
    PDWFormSectionIds.underliers,
    PDWFormSectionIds.productDates,
    PDWFormSectionIds.productGrowth,
    PDWFormSectionIds.protectionDetails,
    PDWFormSectionIds.calendarDates,
    PDWFormSectionIds.callDetails,
    PDWFormSectionIds.couponDetails,
    PDWFormSectionIds.productPreferences,
    PDWFormSectionIds.feeDetails,
    PDWFormSectionIds.cLNDetails,
    PDWFormSectionIds.cMSDetails,
    PDWFormSectionIds.equityBuilders,
    PDWFormSectionIds.nonCoreDetails,
    PDWFormSectionIds.ancillaryFeatures,
    PDWFormSectionIds.clientSpecifies,
  ],
  [TemplateOptions.CAPPED_POINT_TO_POINT]: [
    PDWFormSectionIds.coreDetails,
    PDWFormSectionIds.underliers,
    PDWFormSectionIds.productDates,
    PDWFormSectionIds.productGrowth,
    PDWFormSectionIds.protectionDetails,
    PDWFormSectionIds.calendarDates,
    PDWFormSectionIds.callDetails,
    PDWFormSectionIds.couponDetails,
    PDWFormSectionIds.productPreferences,
    PDWFormSectionIds.feeDetails,
    PDWFormSectionIds.cLNDetails,
    PDWFormSectionIds.cMSDetails,
    PDWFormSectionIds.equityBuilders,
    PDWFormSectionIds.nonCoreDetails,
    PDWFormSectionIds.ancillaryFeatures,
    PDWFormSectionIds.clientSpecifies,
  ],
  [TemplateOptions.CLIQUET]: [
    PDWFormSectionIds.coreDetails,
    PDWFormSectionIds.underliers,
    PDWFormSectionIds.productDates,
    PDWFormSectionIds.calendarDates,
    PDWFormSectionIds.couponDetails,
    PDWFormSectionIds.productGrowth,
    PDWFormSectionIds.protectionDetails,
    PDWFormSectionIds.ancillaryFeatures,
    PDWFormSectionIds.callDetails,
    PDWFormSectionIds.productPreferences,
    PDWFormSectionIds.feeDetails,
    PDWFormSectionIds.cLNDetails,
    PDWFormSectionIds.cMSDetails,
    PDWFormSectionIds.equityBuilders,
    PDWFormSectionIds.nonCoreDetails,
    PDWFormSectionIds.clientSpecifies,
  ],

  [TemplateOptions.CONTINGENT]: [
    PDWFormSectionIds.coreDetails,
    PDWFormSectionIds.underliers,
    PDWFormSectionIds.productDates,
    PDWFormSectionIds.calendarDates,
    PDWFormSectionIds.callDetails,
    PDWFormSectionIds.couponDetails,
    PDWFormSectionIds.protectionDetails,
    PDWFormSectionIds.productGrowth,
    PDWFormSectionIds.productPreferences,
    PDWFormSectionIds.feeDetails,
    PDWFormSectionIds.cLNDetails,
    PDWFormSectionIds.cMSDetails,
    PDWFormSectionIds.equityBuilders,
    PDWFormSectionIds.nonCoreDetails,
    PDWFormSectionIds.ancillaryFeatures,
    PDWFormSectionIds.clientSpecifies,
  ],
  [TemplateOptions.DIGITAL]: [
    PDWFormSectionIds.coreDetails,
    PDWFormSectionIds.underliers,
    PDWFormSectionIds.productDates,
    PDWFormSectionIds.productGrowth,
    PDWFormSectionIds.protectionDetails,
    PDWFormSectionIds.calendarDates,
    PDWFormSectionIds.callDetails,
    PDWFormSectionIds.couponDetails,
    PDWFormSectionIds.productPreferences,
    PDWFormSectionIds.feeDetails,
    PDWFormSectionIds.cLNDetails,
    PDWFormSectionIds.cMSDetails,
    PDWFormSectionIds.equityBuilders,
    PDWFormSectionIds.nonCoreDetails,
    PDWFormSectionIds.ancillaryFeatures,
    PDWFormSectionIds.clientSpecifies,
  ],
  [TemplateOptions.DIGITAL_DUAL_DIRECTIONAL]: [
    PDWFormSectionIds.coreDetails,
    PDWFormSectionIds.underliers,
    PDWFormSectionIds.productDates,
    PDWFormSectionIds.productGrowth,
    PDWFormSectionIds.protectionDetails,
    PDWFormSectionIds.calendarDates,
    PDWFormSectionIds.callDetails,
    PDWFormSectionIds.couponDetails,
    PDWFormSectionIds.productPreferences,
    PDWFormSectionIds.feeDetails,
    PDWFormSectionIds.cLNDetails,
    PDWFormSectionIds.cMSDetails,
    PDWFormSectionIds.equityBuilders,
    PDWFormSectionIds.nonCoreDetails,
    PDWFormSectionIds.ancillaryFeatures,
    PDWFormSectionIds.clientSpecifies,
  ],
  [TemplateOptions.DIGITAL_PLUS]: [
    PDWFormSectionIds.coreDetails,
    PDWFormSectionIds.underliers,
    PDWFormSectionIds.productDates,
    PDWFormSectionIds.productGrowth,
    PDWFormSectionIds.protectionDetails,
    PDWFormSectionIds.calendarDates,
    PDWFormSectionIds.callDetails,
    PDWFormSectionIds.couponDetails,
    PDWFormSectionIds.productPreferences,
    PDWFormSectionIds.feeDetails,
    PDWFormSectionIds.cLNDetails,
    PDWFormSectionIds.cMSDetails,
    PDWFormSectionIds.equityBuilders,
    PDWFormSectionIds.nonCoreDetails,
    PDWFormSectionIds.ancillaryFeatures,
    PDWFormSectionIds.clientSpecifies,
  ],
  [TemplateOptions.P2P_WITH_CONTINGENT_COUPON]: [...DefaultSectionOrder],
  [TemplateOptions.P2P_WITH_FIXED_COUPON]: [...DefaultSectionOrder],
  [TemplateOptions.RANGE_ACCRUAL]: [
    PDWFormSectionIds.coreDetails,
    PDWFormSectionIds.underliers,
    PDWFormSectionIds.productDates,
    PDWFormSectionIds.calendarDates,
    PDWFormSectionIds.callDetails,
    PDWFormSectionIds.couponDetails,
    PDWFormSectionIds.protectionDetails,
    PDWFormSectionIds.productGrowth,
    PDWFormSectionIds.productPreferences,
    PDWFormSectionIds.feeDetails,
    PDWFormSectionIds.cLNDetails,
    PDWFormSectionIds.cMSDetails,
    PDWFormSectionIds.equityBuilders,
    PDWFormSectionIds.nonCoreDetails,
    PDWFormSectionIds.ancillaryFeatures,
    PDWFormSectionIds.clientSpecifies,
  ],
  [TemplateOptions.RATE_BUILDER]: [
    PDWFormSectionIds.coreDetails,
    PDWFormSectionIds.underliers,
    PDWFormSectionIds.productDates,
    PDWFormSectionIds.calendarDates,
    PDWFormSectionIds.couponDetails,
    PDWFormSectionIds.protectionDetails,
    PDWFormSectionIds.callDetails,
    PDWFormSectionIds.productGrowth,
    PDWFormSectionIds.productPreferences,
    PDWFormSectionIds.feeDetails,
    PDWFormSectionIds.cLNDetails,
    PDWFormSectionIds.cMSDetails,
    PDWFormSectionIds.equityBuilders,
    PDWFormSectionIds.nonCoreDetails,
    PDWFormSectionIds.ancillaryFeatures,
    PDWFormSectionIds.clientSpecifies,
  ],
  [TemplateOptions.SNOWBALL]: [
    PDWFormSectionIds.coreDetails,
    PDWFormSectionIds.underliers,
    PDWFormSectionIds.productDates,
    PDWFormSectionIds.calendarDates,
    PDWFormSectionIds.callDetails,
    PDWFormSectionIds.productGrowth,
    PDWFormSectionIds.protectionDetails,
    PDWFormSectionIds.couponDetails,
    PDWFormSectionIds.productPreferences,
    PDWFormSectionIds.feeDetails,
    PDWFormSectionIds.cLNDetails,
    PDWFormSectionIds.cMSDetails,
    PDWFormSectionIds.equityBuilders,
    PDWFormSectionIds.nonCoreDetails,
    PDWFormSectionIds.ancillaryFeatures,
    PDWFormSectionIds.clientSpecifies,
  ],
  [TemplateOptions.TIME_SERIES]: [
    PDWFormSectionIds.coreDetails,
    PDWFormSectionIds.underliers,
    PDWFormSectionIds.productDates,
    PDWFormSectionIds.calendarDates,
    PDWFormSectionIds.couponDetails,
    PDWFormSectionIds.protectionDetails,
    PDWFormSectionIds.callDetails,
    PDWFormSectionIds.productGrowth,
    PDWFormSectionIds.productPreferences,
    PDWFormSectionIds.feeDetails,
    PDWFormSectionIds.cLNDetails,
    PDWFormSectionIds.cMSDetails,
    PDWFormSectionIds.equityBuilders,
    PDWFormSectionIds.nonCoreDetails,
    PDWFormSectionIds.ancillaryFeatures,
    PDWFormSectionIds.clientSpecifies,
  ],
  [TemplateOptions.UNCAPPED_DUAL_DIRECTIONAL]: [
    PDWFormSectionIds.coreDetails,
    PDWFormSectionIds.underliers,
    PDWFormSectionIds.productDates,
    PDWFormSectionIds.productGrowth,
    PDWFormSectionIds.protectionDetails,
    PDWFormSectionIds.calendarDates,
    PDWFormSectionIds.callDetails,
    PDWFormSectionIds.couponDetails,
    PDWFormSectionIds.productPreferences,
    PDWFormSectionIds.feeDetails,
    PDWFormSectionIds.cLNDetails,
    PDWFormSectionIds.cMSDetails,
    PDWFormSectionIds.equityBuilders,
    PDWFormSectionIds.nonCoreDetails,
    PDWFormSectionIds.ancillaryFeatures,
    PDWFormSectionIds.clientSpecifies,
  ],
  [TemplateOptions.UNCAPPED_POINT_TO_POINT]: [
    PDWFormSectionIds.coreDetails,
    PDWFormSectionIds.underliers,
    PDWFormSectionIds.productDates,
    PDWFormSectionIds.productGrowth,
    PDWFormSectionIds.protectionDetails,
    PDWFormSectionIds.calendarDates,
    PDWFormSectionIds.callDetails,
    PDWFormSectionIds.couponDetails,
    PDWFormSectionIds.productPreferences,
    PDWFormSectionIds.feeDetails,
    PDWFormSectionIds.cLNDetails,
    PDWFormSectionIds.cMSDetails,
    PDWFormSectionIds.equityBuilders,
    PDWFormSectionIds.nonCoreDetails,
    PDWFormSectionIds.ancillaryFeatures,
    PDWFormSectionIds.clientSpecifies,
  ],
  [TemplateOptions.FIXED_TO_FLOATING]: [
    PDWFormSectionIds.coreDetails,
    PDWFormSectionIds.underliers,
    PDWFormSectionIds.productDates,
    PDWFormSectionIds.calendarDates,
    PDWFormSectionIds.callDetails,
    PDWFormSectionIds.couponDetails,
    PDWFormSectionIds.protectionDetails,
    PDWFormSectionIds.productGrowth,
    PDWFormSectionIds.productPreferences,
    PDWFormSectionIds.feeDetails,
    PDWFormSectionIds.cLNDetails,
    PDWFormSectionIds.cMSDetails,
    PDWFormSectionIds.equityBuilders,
    PDWFormSectionIds.nonCoreDetails,
    PDWFormSectionIds.ancillaryFeatures,
    PDWFormSectionIds.clientSpecifies,
  ],
  [TemplateOptions.FIXED]: [
    PDWFormSectionIds.coreDetails,
    PDWFormSectionIds.underliers,
    PDWFormSectionIds.productDates,
    PDWFormSectionIds.calendarDates,
    PDWFormSectionIds.callDetails,
    PDWFormSectionIds.couponDetails,
    PDWFormSectionIds.protectionDetails,
    PDWFormSectionIds.productGrowth,
    PDWFormSectionIds.productPreferences,
    PDWFormSectionIds.feeDetails,
    PDWFormSectionIds.cLNDetails,
    PDWFormSectionIds.cMSDetails,
    PDWFormSectionIds.equityBuilders,
    PDWFormSectionIds.nonCoreDetails,
    PDWFormSectionIds.ancillaryFeatures,
    PDWFormSectionIds.clientSpecifies,
  ],
};
