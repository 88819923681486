export const PRODUCT_ENTRY_ROUTE = 'product-entry';
export const PRODUCT_EDIT_ROUTE = 'product-edit';
export const FINAL_REVIEW_QUEUE_ROUTE = 'review/final-review-queue';
export const STAGED_PRODUCTS_ROUTE = 'review/staged-products';
export const REVIEW_QUEUE_ROUTE = 'review/review-queue';
export const SPOTTY_REVIEW_QUEUE_ROUTE = 'review/spotty-review-queue';
export const DESK_CONFIG_ROUTE = 'desk-config';
export const MSWM_EVENTS_ROUTE = 'mswm-events';
export const UPCOMING_HOLIDAYS_ROUTE = 'market-data/upcoming-holidays';
export const UNDERLIERS_ROUTE = 'market-data/underliers';
export const MISSING_MARKET_DATA_ROUTE = 'market-data/missing-market-data';

export const LEARNING_CENTER_COURSE_CONFIG = 'learning-center/course-config';
