import { AppShell } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { ScrollRestoration, useLoaderData } from 'react-router-dom';
import { GlobalNavComponent } from './globalNav/GlobalNav';
import AuthProvider from '../Authentication/AuthProvider';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import TokenOrLogin from './TokenOrLogin';

import { LicenseManager } from 'ag-grid-enterprise';
import {
  DESK_CONFIG_ROUTE,
  FINAL_REVIEW_QUEUE_ROUTE,
  PRODUCT_EDIT_ROUTE,
  PRODUCT_ENTRY_ROUTE,
  REVIEW_QUEUE_ROUTE,
  SPOTTY_REVIEW_QUEUE_ROUTE,
  STAGED_PRODUCTS_ROUTE,
  // UPCOMING_HOLIDAYS_ROUTE,
  UNDERLIERS_ROUTE,
  MISSING_MARKET_DATA_ROUTE,
  LEARNING_CENTER_COURSE_CONFIG,
  MSWM_EVENTS_ROUTE,
} from '../../Routes';
import { WidgetPermissions } from '../../enviroments/WidgetPermissions';
import SpotLightWrapper from './SpotLightWrapper';
import { ModalsProvider } from '@mantine/modals';

LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-061675}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Luma_Financial_Technologies,_LLC}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Luma}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Luma}_need_to_be_licensed___{Luma}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{13_July_2025}____[v3]_[01]_MTc1MjM2MTIwMDAwMA==0650ccdd80271b305beb1c90ed4c096f',
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
});

export interface LinkItem {
  link: string;
  label: string;
  end?: boolean;
  permissions?: WidgetPermissions[];
  links?: LinkItem[];
}

const links: LinkItem[] = [
  {
    label: 'PDW Entry',
    link: '/' + PRODUCT_ENTRY_ROUTE,
    end: true,
    permissions: [
      WidgetPermissions.ProductMaintenanceProductEntry,
      WidgetPermissions.ProductMaintenanceProductEntrySuper,
    ],
  },
  {
    label: 'PDW Edit',
    link: '/' + PRODUCT_EDIT_ROUTE,
    end: false,
    permissions: [WidgetPermissions.ProductMaintenanceProductEdit],
  },
  {
    label: 'Review Queues',
    link: '/review',
    end: false,
    permissions: [
      WidgetPermissions.ReviewQueueAccess,
      WidgetPermissions.RangeToFinalAccess,
      WidgetPermissions.RangeToFinalSuperAccess,
    ],
    links: [
      {
        label: 'Review',
        link: '/' + REVIEW_QUEUE_ROUTE,
        end: true,
        permissions: [WidgetPermissions.ReviewQueueAccess],
      },
      {
        label: 'Spotty Review',
        link: '/' + SPOTTY_REVIEW_QUEUE_ROUTE,
        end: true,
        permissions: [WidgetPermissions.ReviewQueueAccess],
      },
      {
        label: 'Final Review',
        link: '/' + FINAL_REVIEW_QUEUE_ROUTE,
        end: true,
        permissions: [
          WidgetPermissions.RangeToFinalAccess,
          WidgetPermissions.RangeToFinalSuperAccess,
        ],
      },
      {
        label: 'Staged Products',
        link: '/' + STAGED_PRODUCTS_ROUTE,
        end: true,
        permissions: [
          WidgetPermissions.RangeToFinalAccess,
          WidgetPermissions.RangeToFinalSuperAccess,
          WidgetPermissions.ProductMaintenanceProductEdit,
        ],
      },
    ],
  },
  {
    label: 'MSWM Events',
    link: '/' + MSWM_EVENTS_ROUTE,
    permissions: [WidgetPermissions.MenuAdmin],
  },
  {
    label: 'Desk Config',
    link: '/' + DESK_CONFIG_ROUTE,
    end: true,
    permissions: [WidgetPermissions.MenuAdminUsers], // todo: do we need a permission to be able to modify these?
  },
  {
    label: 'Learning Center Config',
    link: '/' + LEARNING_CENTER_COURSE_CONFIG,
    end: true,
    permissions: [WidgetPermissions.MenuAdmin],
  },
  {
    label: 'Market Data Maintenance',
    link: '/market-data',
    end: false,
    permissions: [WidgetPermissions.ProductMaintenanceProductEdit],
    links: [
      // {
      //   label: 'Upcoming Holidays',
      //   link: `/${UPCOMING_HOLIDAYS_ROUTE}`,
      //   end: true,
      //   permissions: [WidgetPermissions.ReviewQueueAccess],
      // },
      {
        label: 'Underliers',
        link: `/${UNDERLIERS_ROUTE}`,
        end: true,
        permissions: [WidgetPermissions.ProductMaintenanceProductEdit],
      },
      {
        label: 'Missing Market Data',
        link: `/${MISSING_MARKET_DATA_ROUTE}`,
        end: true,
        permissions: [WidgetPermissions.ProductMaintenanceProductEdit],
      },
    ],
  },
];

const RootComponent = () => {
  const initAccessTokenResponse = useLoaderData();

  return (
    <QueryClientProvider client={queryClient}>
      <ScrollRestoration />
      <ReactQueryDevtools initialIsOpen={false} />
      <AuthProvider initialTokenResponse={initAccessTokenResponse}>
        <SpotLightWrapper>
          <Notifications style={{ marginBottom: '4rem' }} />
          <ModalsProvider>
            <AppShell
              style={{ paddingTop: 0, minHeight: '90vh' }}
              padding={'md'}
            >
              <AppShell.Header>
                <GlobalNavComponent links={links} />
              </AppShell.Header>
              <TokenOrLogin />
            </AppShell>
          </ModalsProvider>
        </SpotLightWrapper>
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default RootComponent;
